@use '../../includes' as *;

// prevent scrolling in the background when the change station modal is open
body:has(.change_station_dialog[open]) {
  overflow: hidden;
}

.change_station_dialog {
  position: fixed;
  inset: 0;
  z-index: 3;
  width: 100vw;
  height: 100vh;
  background-color: rgba($midnight, 0.1);
  backdrop-filter: blur($blur-radius-lg);

  @include breakpoint($sm) {
    background-color: rgba($midnight, 0.5);
  }
}

.change_station_dialog__inner {
  @include body-copy-1($fog);

  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  margin: auto;
  padding: $g32 $g16;
  border-radius: $radius-med;
  background-color: rgba($navy-blue, 0.8);

  @include breakpoint($xs) {
    padding-inline: $g32;
  }

  @include breakpoint($sm) {
    inset-block-start: 50%;
    inset-inline-start: 50%;
    width: 464px;
    height: max-content;
    transform: translate(-50%, -50%);
  }
}

.change_station_dialog__close {
  position: absolute;
  inset: $g16 $g16 auto auto;
  border: 0;
  background: transparent;
  cursor: pointer;
}

.close_icon {
  width: 15px;
  height: 15px;
}

.change_station_dialog__header {
  margin-block-end: $g16;
}

.change_station_dialog__text {
  margin-block-end: $g12;
}

.stations_list {
  margin-block-end: $g32;
  padding-inline-start: $g24;
}

.stations_list_item {
  margin: 10px 0;
  line-height: 1.4;
}

.station_option {
  margin-block-end: $g8;
  border: 0;
  background-color: transparent;
  font-size: $f12;
  cursor: pointer;

  @include over-title($white);
}

.change_station_dialog__form {
  margin-block-end: $g8;
}

.change_station_dialog__button {
  @include btn;
  @include fill-btn;
}

.change_station_dialog__input {
  max-width: 165px;
  margin-inline-end: $g8;
  padding: $g8 $g12;
  font-size: $f12;
}

.change_station_dialog__select {
  display: inline-block;
  max-width: 200px;
  margin-inline-end: $g8;

  @include selection-dropdown;
}
