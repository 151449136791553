@use '../../includes' as *;

@mixin input {
  box-sizing: border-box;
  height: 38px;
  padding: $g12 $g16;
  border-radius: $radius-ml;
  background-color: rgba($white, 0.2);
  text-align-last: center;

  @include body-copy-3;

  &::placeholder {
    color: $fog;
  }

  &:focus-visible {
    outline: 3px solid $light-blue;
  }
}

.station_lookup_panel__header {
  @include h6;

  margin-block-end: $g8;

  @include breakpoint($sm) {
    margin-block-end: $g12;
  }
}

.station_lookup_panel {
  --button-width: 110px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $g12;
  width: 100%;
  max-width: 400px;
  padding-block: $g8 $g16;

  @include breakpoint($xxs) {
    --button-width: 132px;
  }

  input {
    all: unset;

    @include input;
  }

  select {
    all: unset;

    @include input;

    padding: 10px $g16;
    transition-property:
      background-image, background-color, border-color, color;
    // $white svg from /public/svg/down.svg
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='8' viewBox='0 0 16 8' fill='white'%3E%3Cpath d='M7.8 7.5.6.9V.6C.6.4.7.4.8.4h14.5l.2.3-.1.2-7.2 6.6c-.2.1-.4.1-.4 0Z'/%3E%3C/svg%3E");
    background-position: calc(100% - $g16) center;
    background-repeat: no-repeat;
    cursor: pointer;

    &:hover {
      // $pebble svg from /public/svg/down.svg
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='8' viewBox='0 0 16 8' fill='%2394a1b2'%3E%3Cpath d='M7.8 7.5.6.9V.6C.6.4.7.4.8.4h14.5l.2.3-.1.2-7.2 6.6c-.2.1-.4.1-.4 0Z'/%3E%3C/svg%3E%0A");
    }
  }

  select option {
    background-color: $white;
    color: $midnight;
  }

  button {
    @include body-copy-3;

    justify-content: center;
    width: var(--button-width);
    font-weight: $bold;
  }
}

.station_lookup_panel__form {
  display: flex;
  flex-direction: column;
  gap: $g8;
  width: 100%;
}

.station_lookup_panel__form__inner {
  display: grid;
  grid-template-columns: 1fr var(--button-width);
  gap: $g8;

  @include breakpoint($max: $xxs) {
    // At the very smallest breakpoints we need to tighten these buttons
    button {
      padding-inline: $g8;
    }
  }
}

input.invalid,
select.invalid {
  border: 2px solid $red;
}

.invalid_message {
  @include body-copy-3($moon);

  display: flex;

  svg {
    width: 1em;
    margin-inline-end: $g8;
    fill: $moon;
  }
}
